import { Button, Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import { useNavigate } from "react-router-dom";

export default function ResetPasswordSuccess() {
  const navigate = useNavigate();
  const { oliveGreen, gray } = useThemePalette();

  return (
    <>
      <Stack sx={{ gap: 5 }}>
        <Typography fontFamily="jost" fontSize="32px" fontWeight="400">
          Forgot password?
        </Typography>
        <Typography fontFamily="jost" fontSize="18px" color={gray}>
          Your password has been successfully reset.Click below to login again
        </Typography>
      </Stack>
    </>
  );
}
