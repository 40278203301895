export function makeFilterString(filter_obj) {
    var filterString = "?";
    Object.keys(filter_obj)?.map(function (key) {
      if (filter_obj[key] || filter_obj[key] === false) {
        filterString += key + "=" + filter_obj[key] + "&";
      }
    });
    if (filterString.substr(filterString.length - 1) === "&") {
      filterString = filterString.slice(0, -1);
    }
    return filterString;
  }