import { Box, Stack, Typography } from "@mui/material";

export default function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Stack
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      sx={{ p: 0 }}
    >
      {value === index && <Box>{children}</Box>}
    </Stack>
  );
}
