import {
  Box,
  InputLabel,
  Stack,
  TextField,
  Input,
  Button,
} from "@mui/material";
import React from "react";
import CFormErrors from "./CFormErrors";
import TextFieldInput from "components/Atoms/Input/TextField/TextFieldInput";
import { useState } from "react";
import ColorPicker from "react-pick-color";
import { useThemePalette } from "common/hooks/theme_palette";
import { useEffect } from "react";
import { useRef } from "react";
import rgbHex from "rgb-hex";

const CColorInput = ({
  label,
  placeholder,
  type = "text",
  name,
  formik,
  changeAction,
  ...rest
}) => {
  const value = formik?.values[name];
  const errorsMsg = formik.errors[name];

  const { oliveGreen } = useThemePalette();
  const [color, setColor] = useState("#E6E6E6");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [colorValue, setColorValue] = useState("Pick a color");

  const handleColorChange = (newColor) => {
    setColor(newColor);
    setColorValue(
      "#" +
        rgbHex(
          newColor?.rgb.r,
          newColor?.rgb.g,
          newColor?.rgb.b,
          newColor?.rgb.a
        )
    );
    changeAction(
      "#" +
        rgbHex(
          newColor?.rgb.r,
          newColor?.rgb.g,
          newColor?.rgb.b,
          newColor?.rgb.a
        )
    );
    // setColorValue(newColor.hex)
    // changeAction(newColor.hex)
  };
  const colorPickerRef = useRef(null);

  const toggleColorPicker = () => {
    setShowColorPicker(!showColorPicker);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        colorPickerRef.current &&
        !colorPickerRef.current.contains(event.target)
      ) {
        setShowColorPicker(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Stack mb="5" position="relative">
      <TextFieldInput
        id={name}
        type={type}
        label={label}
        labelStyle={{
          color: "#797979",
          fontSize: "18px",
          fontFamily: "Jost",
          mb: 2,
        }}
        placeholder={placeholder}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#EFEFEF",
            },
            "&:hover fieldset": {
              borderColor: "#EFEFEF",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#EFEFEF",
            },
          },
        }}
        onChange={(e) => changeAction(e.target?.value)}
        value={formik?.values[name]}
        onBlur={formik.handleBlur}
        name={name}
        {...rest}
      />
      <Box ref={colorPickerRef}>
        <Box
          onClick={toggleColorPicker}
          sx={{
            width: "40px",
            height: "40px",
            backgroundColor: formik?.values[name]
              ? formik?.values[name]
              : color,
            borderRadius: "4px",
            display: "flex",
            justifyContent: "space-between",
            position: "absolute",
            right: "8px",
            top: "50px",
            zIndex: 9999999,
          }}
        ></Box>
        <Box>
          {showColorPicker && (
            <div
              className="picker"
              style={{
                background: "#fff",
                borderColor: "#D4D4D4",
                borderRadius: "5px",
                boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
                color: "#262626",
                inputBackground: "#f4f4f4",
                width: "280px",
                padding: "8px",
                position: "absolute",
                right: "0",
                zIndex: 9999999,
              }}
            >
              <ColorPicker
                color="#D80B4D"
                // onChange={function noRefCheck() {  }}
                onChange={handleColorChange}
                theme={{
                  background: "transparent",
                  borderColor: "none",
                  borderRadius: "5px",
                  boxShadow: "none",
                  color: "#262626",
                  inputBackground: "#f4f4f4",
                  width: "222px",
                }}
              />
              <Button
                onClick={toggleColorPicker}
                variant="contained"
                sx={{
                  bgcolor: oliveGreen,
                  width: "100%",
                  borderRadius: "4px",
                  color: "ًwhite !important",
                  marginTop: "8px",
                  "&:hover": {
                    backgroundColor: oliveGreen,
                  },
                }}
              >
                Add
              </Button>
            </div>
          )}
        </Box>
      </Box>
      <CFormErrors errorsMsg={errorsMsg} />
    </Stack>
  );
};

export default CColorInput;
