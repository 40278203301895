import axios from "axios";

export const initAxios = () => {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
  axios.defaults.withCredentials = true;

  axios.interceptors.response.use((response) => response);
};
const setAuthToken = (token) => {
  if (token) {
    // axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios.defaults.headers.common["platform"] = `web`;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

export default setAuthToken;
